<template lang="pug">
.about-section.dataset-details-section.thin-border-primary-lighter.my-6.py-6.px-6
  h3.nio-h3.text-primary-darker Details
  ReviewDataComponent(v-if="dataset", :review-data="dataset" :isEdit="true" @updateDataset="updateDataset")
</template>

<script>

import ReviewDataComponent from '@/shared/components/ReviewDataComponent'

export default {
  components: {
    ReviewDataComponent
  },
  props: {
    dataset: {
      type: Object,
      required: true,
      default: () => { }
    }
  },
  methods: {
    updateDataset(payload) {
      this.$emit('updateDataset', payload)
    },
  }
}
</script>

<style lang="sass" scoped>
.dataset-details-section
  min-height: 25rem
</style>