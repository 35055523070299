<template lang="pug">
  .confirm-delete-mapping-dialog(ref="dialog")
    NioIconFramer(
      icon-name="display-warning"
    )
    h3.nio-h3.text-primary-darker(v-if="multipleDeletions") Delete all mappings to this attribute?
    h3.nio-h3.text-primary-darker(v-else) Delete this mapping?
    p.nio-p.text-primary-dark(v-if="multipleDeletions") This will delete this mapping, as well as any other mappings which reference the same Narrative attribute. You can re-create these mappings later.
    p.nio-p.text-primary-dark(v-else) This will delete this mapping from your dataset to the specified Narrative data attribute. You can re-create this mapping later.
    .actions
      NioButton(
        normal-secondary
        @click="cancel"
      ) Cancel
      NioButton(
        caution-filled-prepend
        @click="confirm"
      ) Confirm
</template>

<script>

export default {
  props: {
    mapping: { type: Object, required: true },
    existingMappings: { type: Array, required: false, default: () => [] }
  },
  computed: {
    multipleDeletions() {
      return this.existingMappings.filter(mapping => mapping.attributeName === this.mapping.attributeName).length > 1
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    confirm() {
      this.$emit('confirm')
    }
  }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.confirm-delete-mapping-dialog
  padding: 100px 24px 78px 24px
  background-color: $c-white
  border: 1px solid $c-primary-lighter
  border-radius: 12px
  display: flex
  flex-direction: column
  align-items: center
  .nio-icon-framer
    margin-bottom: 16px
  h3
    margin-bottom: 24px
  p
    margin-bottom: 76px
    text-align: center
  .actions
    display: flex
    justify-content: center
    align-items: center
    & > * + * 
      margin-left: 16px  
</style>