import numeral from 'numeral'

let formatNumber = (number) => {
  if (isNaN(number))
    return 0
  if (number) {
    if (Math.abs(number) > 1000)
      return numeral(number).format('0a')
    else 
    return numeral(number).format('0,0')
  }
  else return number
}

let formatNumberVerbose = (number) => {
  if (number > 0) {
    return formatNumber(number).replace('m', ' Million').replace('b', ' Billion').replace('k', 'K')
  }
  return '0'
}

let readableFilesize = (bytes, decimal= 2) => {
  if (bytes > 0) {
    var i = Math.floor( Math.log(bytes) / Math.log(1024) );
    return ( bytes / Math.pow(1024, i) ).toFixed(decimal) * 1 + ['B', 'KB', 'MB', 'GB', 'TB'][i];
  }
  return '0B'
}

function formatWithCommas(number) {
  let formatter = new Intl.NumberFormat('en-US')
  return formatter.format(number)
}

export {
  formatNumber,
  formatNumberVerbose,
  readableFilesize,
  formatWithCommas
}