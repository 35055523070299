<template lang="pug">
#top.dataset-details
  .app-loading(v-if="loading")
    v-progress-circular.progress(size="80", color="#1438F5", indeterminate)
  div(v-if="!loading && !!dataset")
    .px-6.py-5
      .d-flex.align-center
        .heading.nio-h5.text-primary.mr-3
          NioButton.back-to-datasets(normal-tertiary @click="backToDatasets") Datasets
        NioIcon.chevron(name="utility-chevron-right", color="#4F64AF")
        .nio-h5.large.text-primary-dark.ml-3 {{ dataset.display_name }}
    .banner-title.d-flex.flex-column.justify-center
      .d-flex.justify-space-between.align-center.px-6.py-5
        .heading
          .heading.nio-h2.text-white {{ dataset.display_name }}
          .nio-p.text-primary-lighter.font-weight-medium {{ description }} ({{ createdAt }})
        .meta-info
          .meta-info-row
            .nio-p.text-primary-light.bold Dataset ID
            .nio-p.text-primary-lighter.font-weight-medium {{ dataset.id }}
          .meta-info-row.justify-space-between
            .nio-p.text-primary-light.bold.font-weight-medium Created
            .nio-p.text-primary-lighter {{ createdAt }}
          .meta-info-row.justify-space-between
            .nio-p.text-primary-light.bold Last ingestion
            .nio-p.text-primary-lighter.font-weight-medium {{ lastIngestionDate }}
    .py-6.px-6
      .d-flex.vertical-links
        a(
          v-for="(tab, id) in tabs",
          :key="tab.name",
          :class="{ link: true, active: tab.name === activeTab }",
          @click="(e) => handleTabClick(e, tab)",
          :href="`#${tab.name}`"
        )
          h5.nio-h5 {{ tab.label }} {{ tab.activeTab }}

      DatasetDetailsSection(:dataset="dataset", @updateDataset="updateDataset")
      FileIngestion(:dataset="dataset")
      AvailableFields(:dataset="dataset", @updateFields="updateFields")
      AttributeMapping(:dataset="dataset", @getDataset="fetchDataset")
      SampleDataset(
        :dataset="dataset",
        :stat="stat"
        :loadingStat="loadingStat"
        :defaultTab="sampleDatasetDefaultTab"
        @getDataset="getDataset"
      )
      .d-flex.justify-center
        NioButton(caution-outlined, @click="deactivate") Deactivate Dataset
      NioDialog(v-model="confirmDeactivateDialog")
        ConfirmDeactivateDialog(
          @cancel="confirmDeactivateDialog = false",
          @confirm="confirmDeactivate"
        ) 
</template>

<script>

import DatasetDetailsSection from "./components/DatasetDetailsSection"
import ConfirmDeactivateDialog from './components/ConfirmDeactivateDialog'
import FileIngestion from "./components/FileIngestion"
import SampleDataset from "./components/SampleDataset"
import AvailableFields from "./components/AvailableFields"
import AttributeMapping from "./components/AttributeMapping"
import { NioOpenApiModule } from "@narrative.io/tackle-box"
import { mapGetters, mapActions } from 'vuex'
import moment from "moment"

export default {
  components: {
    ConfirmDeactivateDialog,
    DatasetDetailsSection,
    FileIngestion,
    SampleDataset,
    AvailableFields,
    AttributeMapping
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      confirmDeactivateDialog: false,
      fetchingDataset: false,
      loadingStat: false,
      stat: [],
      lastIngestion: null,
      activeTab: "about-section",
      sampleDatasetDefaultTab: 0,
      tabs: [
        {
          name: "about-section",
          label: "Details",
        },
        {
          name: "file-ingestion-section",
          label: "Ingestion History",
        },
        {
          name: "available-fields-section",
          label: "Schema",
        },
        {
          name: "attribute-mappings-section",
          label: "Attribute Mappings",
        },
       
      ],
      sampleDatasetTabIds: ['sample-dataset-table-section', 'sample-dataset-statistics-section' ],
      dataset: null,
      loading: true,
      recordsPerPage: 100
    }
  },
  mounted() {
    this.scrollToTop()
    NioOpenApiModule.initCallback(this.openApiInit)
  },
  computed: {
    ...mapGetters(['paginatedIngestionRecords', 'ingestionRecords', 'ingestionRecordsLoading']),
    createdAt() {
      if (!this.dataset) return ""
      return moment(this.dataset.created_at).format("MMM D, YYYY")
    },
    description() {
      return this.shortenText(this.dataset?.description)
    },
    lastIngestionDate() {
      if (!this.lastIngestion) return "N/A"
      return moment(this.lastIngestion.snapshot_created_at).format("MMM D, YYYY")
    }
  },
  watch: {
    ingestionRecordsLoading: {
      handler(val) {
        if (!val) {
          this.handleIngestionRecordsLoaded() 
        }     
      },
      deep: true
    }
  },
  methods: {
    ...mapActions(['getIngestionRecords']),
    deactivate() {
      this.confirmDeactivateDialog = true
    },
    confirmDeactivate() {
      this.loading = true
      this.$nioOpenApi.delete(`/datasets/${this.dataset.id}`)
        .then(r => {
          this.backToDatasets()
        })
    },
    shortenText(text, length = 150) {
      if (!text) return ""
      return text.length > length ? `${text.slice(0, length)}...` : text
    },
    openApiInit() {
      this.getDataset()
      this.getDatasetStatistics()
    },
    handleTabClick(e, tab) {
      e.preventDefault()
      this.activeTab = `${tab.name}`
      if (this.sampleDatasetTabIds.includes(tab.name) ) {
        this.sampleDatasetDefaultTab = tab.name === 'sample-dataset-table-section' ? 0 : 1
      }
      const [el] = document.getElementsByClassName(tab.name)
      el?.scrollIntoView({ behavior: 'smooth' })
    },
    updateFields(properties) {
      const formattedSchema = this.formatSchema(properties)
      if (formattedSchema.sensitive.length) {
        const { sensitive = [] } = this.dataset.schema
        const payload = {
          schema: {
            ...this.dataset.schema,
            ...formattedSchema,
            sensitive: [...sensitive, ...formattedSchema.sensitive]
          }
        }
        this.updateDataset(payload)
      }
    },
    formatSchema(properties, isArray) {
      let result = {}

      let curRequired = []
      let curUnsellable = []
      let curSensitive = []

      properties.forEach(field => {
        result[field.name] = {
          type: field.type
        }
        if (field.description?.length > 0) result[field.name].description = field.description
        if (field.approximate_cardinality) result[field.name].approximate_cardinality = field.approximate_cardinality
        if (field.fieldValues === 'enum' && field.enum?.length > 0) result[field.name].enum = field.enum
        const arrPath = field.path.split('/')
        arrPath.shift()
        if (!field.is_optional) {
          curRequired.push(arrPath[arrPath.length - 1])
        }
        if (!field.is_sellable) {
          curUnsellable.push(arrPath[arrPath.length - 1])
        }

        if (field.is_sensitive) {
          curSensitive.push(arrPath[arrPath.length - 1])
        }

        if (field.type === 'object' || field.type === 'array') {
          let childResult
          if (field.type === 'object' && field.properties.length > 0) {
            childResult = this.formatSchema(field.properties, false)
            result[field.name].properties = childResult.properties
            if (childResult.required?.length > 0) result[field.name].required = childResult.required
            if (childResult.unsellable?.length > 0) result[field.name].unsellable = childResult.unsellable
            if (childResult.sensitive?.length > 0) result[field.name].sensitive = childResult.sensitive

          } else if (field.type === 'array' && field.items.length === 1) {
            childResult = this.formatSchema(field.items, true)
            result[field.name].items = childResult.properties.nioArrayItemsField
          }
        }
      })
      return {
        properties: result,
        required: curRequired.flat().map(path => path.replaceAll('.nioArrayItemsField', '')),
        unsellable: curUnsellable.flat().map(path => path.replaceAll('.nioArrayItemsField', '')),
        sensitive: curSensitive.flat().map(path => path.replaceAll('.nioArrayItemsField', ''))
      }
    },
    scrollToTop() {
      parent.postMessage({
          name: 'scrollTo',
          payload: {
            x: 0,
            y: 0
          }
        },"*")
    },
    updateDataset(data) {
      this.loading = true
      const payload = { ...this.dataset, ...data }
      this.$nioOpenApi.put(`/datasets/${this.id}`, payload)
        .then(() => {
          this.getDataset()
        }, (error) => {
          this.loading = false
          parent.postMessage({
            name: 'pageNavigation',
            payload: `error/${encodeURIComponent(JSON.stringify(error))}`
          },"*")
        })
    },
    fetchDataset(callback) {
        this.$nioOpenApi
        .get(`/datasets/${this.id}`).then((r) => {
          this.dataset = r.data
          if(callback){
            callback()
          }
        })
    },
    getDataset() {
      this.loading = true
      this.$nioOpenApi
        .get(`/datasets/${this.id}`)
        .then((r) => {
          this.dataset = r.data
          this.loading = false
          if(this.dataset?.schema?.file_config?.type === "flat") {
            this.tabs.push( {
              name: "sample-dataset-table-section",
              label: "Sample Data",
            })
            this.tabs.push( {
              name: "sample-dataset-statistics-section",
              label: "Statistics",
            })
          }
        })
        .catch(() => (this.loading = false))
    },
    async getDatasetStatistics() {
      this.loadingStat = true
      await this.getIngestionRecords({
        datasetId: parseInt(this.id),
        recordsPerPage: this.recordsPerPage,
        openApi: this.$nioOpenApi
      })
    },
    handleIngestionRecordsLoaded() {
      this.loading = false
      if (this.ingestionRecords?.length > 0) {
        const [lastIngestion] = this.ingestionRecords
        this.stat = this.ingestionRecords
        this.lastIngestion = lastIngestion
        this.loadingStat = false
      }
    },
    backToDatasets() {
      parent.postMessage({
        name: 'pageNavigation',
        payload: `datasets`
      },"*")
    },
  }
}
</script>

<style lang="sass" scoped>
@import "@narrative.io/tackle-box/src/styles/global/_colors"
.dataset-details
  .heading
    .back-to-datasets
      text-transform: none
      &:active
        border: none !important
  .vertical-links
    display: flex
    justify-content: center
    border-bottom: 0.125rem solid $c-primary-light
    align-items: center
    height: 3rem
    .link
      text-decoration: none
      text-transform: uppercase
      color: $c-primary-dark
      padding: 0.8rem 0.875rem
    .link.active
      border-bottom: 0.3125rem solid $c-primary
      color: $c-primary
  .banner-title
    background: $c-primary-darker
    min-height: 8.125rem
    color: $c-white
    .heading
      max-width: 31.25rem
    .meta-info
      .meta-info-row
        border-bottom: 0.0625rem solid $c-primary-dark
        display: grid
        grid-template-columns: 1fr 1fr
        grid-gap: 1.25rem
        padding: 0.5rem
        &:last-child
          border-bottom: none
</style>