<template lang="pug">
v-data-table.sample-table(
  :headers="headers",
  :items="items",
  :hide-default-header="true",
  :footer-props="{ disableItemsPerPage: true }",
  v-if="showTable"
)
  template(v-slot:header="headerProps")
    tr
      th.first
      th(v-for="(header, h) in headerProps.props.headers", :key="header.label")
        .d-flex.align-center
          .mr-2
            NioIcon(
              :name="dataTypeIconName(header.label)",
              :size="24",
              color="#AEB9E8"
            )
          span {{ header.label }}
  template(v-slot:body="sampleProps")
    tbody
      tr(v-for="(sampleRow, i) in sampleProps.items", :key="i")
        td.first {{ (i + (sampleProps.pagination.page - 1) * sampleProps.pagination.itemsPerPage) + 1  < 10 ? `0${(i + (sampleProps.pagination.page - 1) * sampleProps.pagination.itemsPerPage) + 1 }` : (i + (sampleProps.pagination.page - 1) * sampleProps.pagination.itemsPerPage) + 1  }}
        td(v-for="(val, j) in makeRow(sampleRow)", :key="j") {{ val !== null ? val : 'N/A' }}
</template>

<script>

import { getDataTypeIconName } from "@narrative.io/tackle-box/src/modules/app/schema/attributeModule"

export default {
  props: {
    dataset: {
      type: Object,
      required: true
    },
    headers: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    page: {
      type: Number,
      default: 0
    }
  },
  methods: {
    makeRow(sampleRow) {
      let row = []
      Object.keys(sampleRow).forEach(key => {
        const order = this.dataset.schema.properties[key].order
        row[order] = sampleRow[key]
      });
      return row
    }, 
    showTable() {
      return this.header.length > 0 && this.items.length > 0
    },
    dataTypeIconName(columnName) {
      const type = this.dataset.schema.properties[columnName]?.type || 'string'
      return getDataTypeIconName({type})
    },
  }
}
</script>

<style lang="sass" scoped>
@import "@narrative.io/tackle-box/src/styles/global/_colors"
$bg: #F4F7FB
$color: #4F64AF

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper)
  background: none
::v-deep .theme--light.v-data-table .v-data-footer
  color: $color

::v-deep .theme--light.v-btn.v-btn--icon
  color: $c-primary-light

::v-deep .theme--light.v-btn.v-btn--disabled .v-icon
  color: $c-primary-lightest !important

::v-deep .v-data-footer
  color: $color
  border-top: none

::v-deep .v-data-footer__select
  display: none

::v-deep .v-data-table__wrapper
  &::-webkit-scrollbar
    height: 0.625rem
    width: 0.625rem
    background-color: $bg

  &::-webkit-scrollbar-thumb:horizontal
    background: $c-primary-lighter
    border-radius: 0.625rem

.sample-table
  border-collapse: collapse
  width: 100%
  tr
    th
      background-color: $bg
      padding: 0.75rem 0.9375rem
      color: $color
      border: 0.0625rem solid
      border-color: $c-primary-lightest
      text-align: left
      font-weight: 500
      font-size: 0.75rem
      text-transform: uppercase
      min-width: 12.5rem

    td
      padding: 0.5rem 1rem
      border: 0.0625rem solid
      border-color: $c-primary-lightest
      color: $c-primary-dark
      font-weight: 400
      font-size: 0.875rem
      text-transform: capitalize
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

    th.first, td.first
      min-width: 1.25rem
      background-color: $bg
  ::v-deep .v-data-footer
    padding-top: 1.5rem
</style>