<template lang="pug">
.sample-chart
  .d-flex.justify-end.align-center.mb-4(v-if="totalData.length")
    .nio-h4.text-primary-darker {{ Math.min(page * perPage, totalData.length) }} of {{ totalData.length }} columns
    .d-flex.align-center
      NioButton.px-4.py-2(container, @click="showPrevious()")
        NioIcon(
          name="utility-chevron-left",
          :size="16",
          color="#AEB9E8",
          
        ) Previous
      NioButton.px-4.py-2(container, @click.prevent="showNext()")
        NioIcon(name="utility-chevron-right", :size="16", color="#AEB9E8") Next
  ColumnChart(
    :options="column",
    :key="column.columnName",
    v-for="(column, i) in currentData"
  )
  .d-flex.justify-end.align-center.mb-4(v-if="totalData.length")
    .nio-h4.text-primary-darker {{ Math.min(page * perPage, totalData.length) }} of {{ totalData.length }} columns
    .d-flex
      NioButton(container)
        NioIcon(
          name="utility-chevron-left",
          :size="16",
          color="#AEB9E8",
          @click="showPrevious()"
        ) Previous
      NioButton(container, @click.prevent="showNext()")
        NioIcon(name="utility-chevron-right", :size="16", color="#AEB9E8") Next
</template>

<script>

import ColumnChart from "./ColumnChart"
// import getBackendData from "./backendData"

export default {
  components: {
    ColumnChart,
  },
  props: {
    stat: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentData: [],
      totalData: [],

      options: {
        width: 400,
        height: 200,
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        data: [],
        series: [
          {
            type: "histogram",
            xKey: "ratio",
            xName: "Ratio",
            yKey: "abosolute",
            yName: "Absolute",
            aggregation: "count",
            fill: "#4F64AF",
            strokeWidth: 1,
            stroke: "white",
            shadow: {
              enabled: false,
            },
            highlightStyle: {
              item: {
                strokeWidth: 0,
                fill: "#AEB9E8",
              },
            },
          },
        ],
        legend: {
          enabled: false,
        },
        axes: [
          {
            type: "number",
            position: "bottom",
            min: 0,
            max: 100,
            gridStyle: {
              stroke: "#fff",
              lineDash: [0, 0],
            },
            tick: {
              width: 0,
            },
            line: {
              width: 2,
              color: "#AEB9E8",
            },
            label: {
              color: "#4F64AF",
              fontSize: 10,
              fontWeight: "normal",
              formatter: ({ value }) => {
                return value === 0 || value === 100 ? `${value}` : undefined
              },
            },
          },
          {
            type: "number",
            position: "left",
            line: {
              width: 0,
            },
            tick: {
              width: 0,
            },
            label: {
              color: "#fff",
            },
          },
        ],
      },
      page: 0,
      perPage: 5,
      sumKeys: new Set(['column_store_bytes', 'nan_value_count', 'null_value_count', 'value_count'])
    }
  },
  mounted() {
    this.totalData = this.processData()

    this.showNext()
  },
  methods: {
    // getBackendData,
    processData() {
      // const [allData] = this.getBackendData()
      const stat = this.processStat()
      // const metricMaps = allData?.analyzerContext?.metricMap
      // const histogramData = metricMaps?.reduce((acc, { metric, analyzer } = {}) => {
      //   const values = metric?.value?.values || {}
      //   const columnName = analyzer?.column

      //   if (acc[columnName]) {
      //     acc[columnName] = {
      //       ...acc[columnName],
      //       data: [...acc[columnName].data, ...(Object.values(values) || [])]
      //     }
      //   } else {
      //     acc[columnName] = {
      //       columnName: analyzer?.column,
      //       data: (Object.values(values) || []),
      //     }
      //   }
      //   return acc
      // }, {})
      // const processedHistogramData = Object.values(histogramData)?.filter(obj => !!obj.columnName) || []

      const columnData = stat.map((data) => {
        return { ...this.options, data: [], columnName: data.name, column: data }
      })
      return columnData;
    },
    isNumber(value) {
      return typeof value === "number"
    },
    computeNewColumn(previous, current) {
      return Object.entries(previous).reduce((acc, [key, value]) => {
        if (key === "lower_bound" && this.isNumber(value) && this.isNumber(current[key])) {
          acc[key] = Math.min(value, current[key])
        }
        if (key === "upper_bound" && this.isNumber(value) && this.isNumber(current[key])) {
          acc[key] = Math.min(value, current[key])
        }
        if (this.sumKeys.has(key)) {
          const previousValue = value || 0
          const currentValue = current[key] || 0
          acc[key] = previousValue + currentValue
        } else {
          acc[key] = current[key]
        }
        return acc
      }, {})
    },
    processStat() {
      const result = this.stat?.reduce((acc, { columns_summary }) => {
        columns_summary.forEach((column) => {
          if (acc[column.name]) {
            const previous = acc[column.name]
            acc[column.name] = this.computeNewColumn(previous, column)
          } else {
            acc[column.name] = column
          }
        })
        return acc
      }, {})
      return Object.values(result).filter(stat => !stat.name.startsWith("_")) || []
    },
    showPrevious() {
      if (this.page === 1) return
      this.page -= 1
      this.currentData = [...this.paginate(this.totalData, this.page, this.perPage)]
    },
    paginate(data, page, perPage) {
      const start = perPage * (page - 1)
      const end = perPage * page
      return data.slice(start, end)
    },
    showNext() {
      if (this.page * this.perPage >= this.totalData.length) {
        return
      }
      this.page += 1
      this.currentData = [...this.paginate(this.totalData, this.page, this.perPage)]
    }
  },
}
</script>