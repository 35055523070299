import { render, staticRenderFns } from "./DatasetStatistics.vue?vue&type=template&id=564e4052&scoped=true&lang=pug&"
import script from "./DatasetStatistics.vue?vue&type=script&lang=js&"
export * from "./DatasetStatistics.vue?vue&type=script&lang=js&"
import style0 from "./DatasetStatistics.vue?vue&type=style&index=0&id=564e4052&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "564e4052",
  null
  
)

export default component.exports