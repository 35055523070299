import { render, staticRenderFns } from "./SampleDataset.vue?vue&type=template&id=79ad9499&scoped=true&lang=pug&"
import script from "./SampleDataset.vue?vue&type=script&lang=js&"
export * from "./SampleDataset.vue?vue&type=script&lang=js&"
import style0 from "./SampleDataset.vue?vue&type=style&index=0&id=79ad9499&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79ad9499",
  null
  
)

export default component.exports