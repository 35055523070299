<template lang="pug">
  .ingestion-records-pagination
    NioButton.prev-page-button(
      v-if="showPrevPageButton"
      iconName="utility-arrow-left"
      normal-tertiary-prepend
      prepend-icon
      @click="$emit('prevPage')"
    ) Prev
    .pagination-message.nio-p.text-primary-dark showing <strong>{{ paginationMessageMin }}</strong> to <strong>{{ paginationMessageMax }}</strong> of <strong>{{ paginationMessageTotal }}</strong>
    NioButton.next-page-button(
      v-if="showNextPageButton"
      iconName="utility-arrow-right"
      normal-tertiary-append
      @click="$emit('nextPage')"
    ) Next
</template>

<script>

export default {
  props: {
    paginationMessageMin: { type: Number, required: true },
    paginationMessageMax: { type: Number, required: true },
    paginationMessageTotal: { type: Number, required: true },
    showPrevPageButton: { type: Boolean, required: true },
    showNextPageButton: { type: Boolean, required: true },
  }
}
</script>

<style lang="sass" scoped>
@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-header"

.ingestion-records-pagination
  display: flex
  align-items: center
  flex-wrap: nowrap
  height: 2.5rem
  & > * + * 
    margin-left: 0.5rem
  .pagination-message
    margin-bottom: 0.1875rem
  .next-page-button
    margin-bottom: 0.125rem

</style>