<template lang="pug">
.sample-dataset
  .sample-dataset-table-section
  .sample-dataset-statistics-section
  .sample-dataset-inner.thin-border-primary-lighter.my-6.py-6.px-6
    .d-flex.justify-space-between
      h3.nio-h3.text-primary-darker.mb-4 Sample Data and Dataset Statistics
      .d-flex
        NioButton.icon-button(
          v-if="sample && sample.length > 0",
          :disabled="downloadDisabled",
          icon-name="utility-download",
          icon-color="red",
          normal-icon,
          @click="downloadCsv"
        )
    .d-flex.justify-center.loading.mt-5.pt-5(v-if="loading")
      v-progress-circular.progress(size="40", color="#1438F5", indeterminate)
    .sample-dataset-content(v-else)
      NioTabs(:tabs="tabs", v-model="activeTab")
        template(v-slot:sample-dataset-table)
          .sample-table-wrapper(v-if="sample.length")
            SampleTable(
              :headers="sampleHeaders"
              :items="sample"
              :dataset="dataset"
              v-if="schemaType === 'flat'"
            )
            NioJsonViewer(
              :json="sampleDataWithLimit"
              :depth="0"
              :defaultExpand="false"
              v-if="schemaType === 'json'"
            )

          .d-flex.justify-center.mt-6.pt-6(v-if="!sample.length && !loading")
            .nio-p.text-primary-dark No Sample Data Available
        template(v-slot:sample-dataset-statistics)
          SampleChart(
            v-if="stat.length"
            :stat="stat" 
          )
          .d-flex.justify-center.mt-6.pt-6(v-else)
            .nio-p.text-primary-dark No Sample Statistics Available
  
</template>

<script>

import { NioOpenApiModule } from "@narrative.io/tackle-box"
import SampleTable from "./sample-dataset-section/SampleTable.vue"
import SampleChart from './sample-dataset-section/SampleChart.vue'

export default {
  components: {
    SampleTable,
    SampleChart
  },
  data() {
    return {
      jsonLimit: 25,
      loading: true,
      activeTab: 0,
      tabs: [
        {
          name: "sample-dataset-table",
          label: "Sample Data",
        },
        {
          name: "sample-dataset-statistics",
          label: "statistics",
        },
      ],
      sampleHeaders: [],
      sample: [],
      downloadDisabled: false
    }
  },
  props: {
    dataset: {
      type: Object,
    },
    stat: {
      type: Array,
      default: () => []
    },
    defaultTab: {
      type: Number,
      default: 0
    }
  },
  computed: {
    schemaType() {
      return this.dataset.schema?.file_config?.type
    },
    sampleDataWithLimit() {
      return this.sample.slice(0, this.jsonLimit)
    }
  },
  methods: {
    openApiInit() {
      if (!this.dataset) return
      this.getSampleData()
    },
    getSampleData() {
      const datasetId = this.dataset.id
      this.$nioOpenApi
        .get(`/datasets/${datasetId}/sample`)
        .then((resp) => {
          this.loading = false
          if (resp.data.records && resp.data.records.length > 0) {
            const keys = Object.keys(this.dataset.schema.properties).sort((a,b) => a.order - b.order).filter(key => !key.startsWith("_nio"))
            this.sample = resp.data.records.reduce((acc, sample) => {
              let obj = {}
              const entries = Object.entries(sample).reduce((acc, [key, value]) => {
                acc[key] = { key, value }
                return acc
              }, {})
              keys.forEach(key => {
                obj[key] = entries[key] ? entries[key].value : null
              })
              acc.push(obj)
              return acc
            }, [])
            const [firstData] = this.sample
            const sampleHeaders = []
            Object.keys(firstData).forEach(key => {
              const order = this.dataset.schema.properties[key].order
              sampleHeaders[order] = {
                label: key,
                type: this.dataset.schema.properties[key].type
              }
            })
            this.sampleHeaders = sampleHeaders 
          }
          this.loading = false
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    downloadCsv() {
      const csvdata = this.makeCsv(this.sample)
      const blob = new Blob([csvdata], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)
      const el = document.createElement('a')
      el.setAttribute('href', url)
      el.setAttribute('download', 'narrative-dataset-sample.csv')
      el.click()
      this.downloadDisabled = true
      setTimeout(() => {
        this.downloadDisabled = false
      }, 1000);
    },
    makeCsv(data) {
      const csvRows = []
      const headers = this.sampleHeaders.map(header => header.label)
      csvRows.push(headers.join(','))
      data.forEach(row => {
        let values = []
        Object.keys(row).forEach(key => {
          const order = this.dataset.schema.properties[key].order
          values[order] = row[key]
        });
        csvRows.push(values)
      })
      return csvRows.join('\n')
    }
  },
  mounted() {
    NioOpenApiModule.initCallback(this.openApiInit)
  },
  watch: {
    defaultTab(val) {
      this.activeTab = val
    }
  }
}
</script>
<style lang="sass" scoped>
@import "@narrative.io/tackle-box/src/styles/global/_colors"
.sample-dataset
  &-inner
    min-height: 31.25rem
  ::v-deep .theme--light.v-tabs > .v-tabs-bar
    .v-slide-group__content.v-tabs-bar__content
      justify-content: flex-start
  .icon-button
    margin-left: 0.5rem
    border: 0.0625rem solid red
    ::v-deep svg
      color: $c-primary-dark
</style>