<template lang="pug">
.dataset-statistics
  .global-statistics
    .title-description
      .filter-title.nio-h4.text-primary-darker Current total size
    .filter-value
      table
        tr
          th.nio-h7.text-primary-dark Number of rows
          th.nio-h7.text-primary-dark File size
          th.nio-h7.text-primary-dark Last ingestion date
        tr(v-if="ingestionRecordsLoading")
          td 
            v-progress-circular.statistics-loading(
              :size="24",
              color="#1438F5",
              indeterminate
            )
          td 
            v-progress-circular.statistics-loading(
              :size="24",
              color="#1438F5",
              indeterminate
            )
          td 
            v-progress-circular.statistics-loading(
              :size="24",
              color="#1438F5",
              indeterminate
            )
        tr(v-else)
          td.nio-p.text-primary-dark {{ statisticsNumRows }}
          td.nio-p.text-primary-dark {{ statisticsFilesize }}
          td.nio-p.text-primary-dark {{ statisticsLastIngestion }}
  .ingestion-log(v-if="!ingestionRecordsLoading && ingestionRecordsExist")
    NioSelect(
      v-if="timeRangeOptions"
      v-model="selectedTimeRange" 
      :items="timeRangeOptions" 
      item-text="label"
      item-value="value"
    )
    chart(
      type="line"
      :options="chartOptions"
      :series="generateSeries"
    )
  .ingestion-summary.pt-4.pb-4(v-if="!ingestionRecordsLoading && ingestionRecordsExist")
    .title-description
      .filter-title.nio-h4.text-primary-darker.d-flex.align-center
        span Ingestion History
        NioTooltip.mr-2(
          open-on-hover,
          message="Ingestion History"
        )
      .description.nio-p.text-primary-dark Size of the dataset at time of ingestion
    .filter-value(
      v-if="pageHasIngestionRecords"
    )
      IngestionRecordsPagination.top(
        v-if="currentPageIngestionRecords.length > 5"
        :pagination-message-min="paginationMessageMin"
        :pagination-message-max="paginationMessageMax" 
        :pagination-message-total="ingestionRecords.length"
        :show-prev-page-button="showPrevPageButton"
        :show-next-page-button="showNextPageButton"
        @prevPage="prevPage"
        @nextPage="nextPage"
      )
      table
        tr
          th.nio-h7.text-primary-dark Number of rows
          th.nio-h7.text-primary-dark File size
          th.nio-h7.text-primary-dark Last ingestion date
        tr(
          v-for="row of currentPageIngestionRecords"
          :key="row.snapshot_created_at"
        )
          td.nio-p.text-primary-dark {{ formatWithCommas(row.active_dataset_stored_records) }}
          td.nio-p.text-primary-dark {{ readableFilesize(row.active_dataset_stored_bytes) }}
          td.nio-p.text-primary-dark {{ formatDate(row.snapshot_created_at) }}
      .ingestion-records-actions
        NioButton(
          v-if="!showMore && paginatedIngestionRecords[pageNumber].length > 5"
          normal-tertiary
          @click="showMore = true"
        ) Show more
        NioButton(
          v-if="showMore"
          normal-tertiary
          @click="showMore = false; scrollToIngestionRecords()"
        ) Show less
        IngestionRecordsPagination.bottom(
          v-if="currentPageIngestionRecords.length > 5"
          :pagination-message-min="paginationMessageMin"
          :pagination-message-max="paginationMessageMax" 
          :pagination-message-total="ingestionRecords.length"
          :show-prev-page-button="showPrevPageButton"
          :show-next-page-button="showNextPageButton"
          @prevPage="prevPage(true)"
          @nextPage="nextPage(true)"
        )
  .module.file-upload.d-flex.pt-4
    .title-description
      .filter-title.nio-h4.text-primary-darker Upload files
    .filter-value
      .nio-p.text-primary-dark.mb-3 Files will be ingested automatically from your managed AWS S3 Bucket (if configured)
      NioButton(
        normal-secondary,
        @click="showIngestionUploadDialog()"
      ) Upload Files Manually
  NioDialog(v-model="ingestionUploadDialog")
    IngestionUploadDialog(
      v-if="dataset",
      :dataset="dataset",
      @close="ingestionUploadDialog = false"
    ) 
</template>

<script>

import { formatNumberVerbose, readableFilesize, formatWithCommas } from "@/shared/utils/helpers"
import { NioOpenApiModule } from "@narrative.io/tackle-box"
import moment from "moment"
import  IngestionUploadDialog from './IngestionUploadDialog'
import IngestionRecordsPagination from './IngestionRecordsPagination'
import { mapGetters } from 'vuex'
// import '@/plugins/chart'

export default {
  components: {
    IngestionUploadDialog,
    IngestionRecordsPagination
  },
  props: {
    dataset: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    datasetStatistics: null,
    ingestionUploadDialog: false,
    showMore: false,
    pageNumber: 0,
    recordsPerPage: 100,
    selectedTimeRange: 'All time',
    timeRangeOptions: null,
    timeRangeError: false,
    periodOptions: ['All time','Month to date','Last month','Last 3 months'],
    chartOptions: {
      chart: {
        id: 'dataset-statistics-chart',
        toolbar: {
          show: false
        }
      },
      stroke: {
        curve: 'straight',
        colors: ['#14317d'],
        lineCap: 'round'
      },
      markers: {
        size: 0
      },
      colors: ['#14317d','#dd3f35'],
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeFormatter: {
            year: 'yyyy',
            month: 'MMM \'yy',
            day: 'dd MMM',
            hour: 'HH:mm'
          }
        },
      },
      yaxis: {
          min: 0,
          title: {
            text: 'NUMBER OF ROWS'
          },
          labels: {
            formatter: (value) => formatNumberVerbose(value)
          }
      },
      tooltip: {
        marker: {
          show: false
        }, 
        x: {
          format: 'MMMM'
        },
        y: {
          title: {
            formatter: (seriesName) => ''
          },
          formatter: function(value, opts) {
            return (
              '<div class="tooltip">' + 
                '<p class="num-rows">' + formatWithCommas(opts.series[opts.seriesIndex][opts.dataPointIndex]) + ' Rows' + '</p>' + 
                '<p class="file-size">' + opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].description + '</p>' +
              '</div>'
            )
          }
        }
        
      }
    }
  }),
  computed: {
    ...mapGetters(['paginatedIngestionRecords', 'ingestionRecords', 'ingestionRecordsLoading']),
    statisticsNumRows() {
      if (
        this.datasetStatistics &&
        this.datasetStatistics.active_dataset_stored_records
      ) {
        return formatWithCommas(
          this.datasetStatistics.active_dataset_stored_records
        )
      }
      return "0"
    },
    statisticsFilesize() {
      if (
        this.datasetStatistics &&
        this.datasetStatistics.active_dataset_stored_bytes
      ) {
        return readableFilesize(
          this.datasetStatistics.active_dataset_stored_bytes
        )
      }
      return "0B"
    },
    statisticsLastIngestion() {
      if (
        this.datasetStatistics &&
        this.datasetStatistics.snapshot_created_at
      ) {
        return this.formatDate(this.datasetStatistics.snapshot_created_at)
      }
      return "N/A"
    },
    ingestionRecordsExist() {
      return this.paginatedIngestionRecords && Object.keys(this.paginatedIngestionRecords).length > 0
    },
    pageHasIngestionRecords() {
      return this.paginatedIngestionRecords[this.pageNumber] && this.paginatedIngestionRecords[this.pageNumber].length > 0
    },
    currentPageIngestionRecords() {
      return this.showMore ? this.paginatedIngestionRecords[this.pageNumber] : this.paginatedIngestionRecords[this.pageNumber].slice(0, 5)
    },
    showNextPageButton() {
      return this.showMore && this.paginatedIngestionRecords[this.pageNumber].length === this.recordsPerPage
    },
    showPrevPageButton() {
      return this.showMore && this.pageNumber > 0
    },
    paginationMessageMin() {
      return this.recordsPerPage * (this.pageNumber) + 1
    },
    paginationMessageMax() {
      return this.pageNumber > 0 ? this.recordsPerPage * (this.pageNumber) + this.currentPageIngestionRecords.length : this.currentPageIngestionRecords.length
    },
    generateSeries() {
      const data = []
      Object.values(this.ingestionRecords).forEach(page => data.push(page))
      const series = [{
          data: data.map(row => {
            return {
              x: row.snapshot_created_at,
              y: row.active_dataset_stored_records,
              description: readableFilesize(row.active_dataset_stored_bytes)
            }}).reverse()
      }]
      return this.ingestionRecords ? series : null
    } 
  },
  watch: {
    ingestionRecordsLoading: {
      handler(val) {
        if (!val) {
          this.handleIngestionRecordsLoaded() 
        }     
      },
      deep: true
    },
    selectedTimeRange: {
      handler(val) {
        this.updateChart(val)
      }
    }
  },
  mounted() {
    NioOpenApiModule.initCallback(this.openApiInit)
    this.timeRangeOptions = this.periodOptions
  },
  methods: {
    formatWithCommas,
    updateChart(val) {
      const series = this.generateSeries
      const targetYear = new Date(series[0].data[series[0].data.length - 1].x).getFullYear()
      const targetMonth = new Date(series[0].data[series[0].data.length - 1].x).getMonth() 
      const mostRecentDay = new Date(series[0].data[series[0].data.length - 1].x).getDate()
      const threeMonthsAgo = new Date(new Date(new Date(targetYear, targetMonth, 0).setDate(0)).setDate(0)).setDate(1)
      switch(val) {
        case 'All time':
          this.$apexcharts.exec('dataset-statistics-chart', 'resetSeries')
          break
        case 'Month to date':
          this.$apexcharts.exec('dataset-statistics-chart', 'zoomX',new Date(targetYear, targetMonth, mostRecentDay).setDate(1), new Date(targetYear, targetMonth, mostRecentDay))
          break
        case 'Last month':
          this.$apexcharts.exec('dataset-statistics-chart', 'zoomX',new Date(targetYear, targetMonth, 0).setDate(1), new Date(targetYear, targetMonth, 0))
          break
        case 'Last 3 months':
          this.$apexcharts.exec('dataset-statistics-chart', 'zoomX',threeMonthsAgo, new Date(targetYear, targetMonth, mostRecentDay))
          break
        default:
          this.$apexcharts.exec('dataset-statistics-chart', 'resetSeries')
      }
    },
    showIngestionUploadDialog() {
      this.ingestionUploadDialog = true
    },
    async openApiInit() {
      if (!this.dataset) return
    },
    handleIngestionRecordsLoaded() {
      if (this.ingestionRecords?.length > 0) {
        this.datasetStatistics = this.ingestionRecords[0]
      }
    },
    formatNumberVerbose(val) {
      return formatNumberVerbose(val)
    },
    readableFilesize(val) {
      return readableFilesize(val)
    },
    formatDate(date) {
      return `${moment(date).format("MMM D, YYYY HH:mm")}`
    },
    prevPage(scrollToTop = false) {
      if (scrollToTop) {
        this.scrollToIngestionRecords()
      }
      this.pageNumber -= 1
    },
    async nextPage(scrollToTop = false) {
      if (scrollToTop) {
        this.scrollToIngestionRecords()
      }
      this.pageNumber += 1
    },
    scrollToIngestionRecords() {
      parent.postMessage({
          name: 'scrollTo',
          payload: {
            x: 0,
            y: 810
          }
        },"*")
    }
  },
}
</script>

<style lang="sass" scoped>
@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-header"

.dataset-statistics

  .full-width
    width: 100%
    width: 100%
  .global-statistics, .ingestion-summary, .ingestion-log
    .nio-select
      max-width: 12.5rem
      margin-bottom: 0px
      margin-top: 0.625rem
      ::v-deep .v-select__selections
        padding-top: 0.625rem
    +nio-filter-header
    padding: 0
    flex-direction: column
    width: 100%
    border-top: 0.0625rem solid $c-primary-lighter
    &:nth-child(1)
      border-top: none
    .filter-value
      width: 100%
  .module.file-upload
    border-top: 0.0625rem solid $c-primary-lighter
    & > *
      width: 50%
  .ingestion-records-actions
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: 0.5rem
  .ingestion-records-pagination
    margin-right: 11.625rem
    &.top
      position: absolute
      right: 0rem
      top: 2rem
    &.bottom
  table
    margin-top: 0.5rem
    width: 100%
    .loading-page
      position: absolute
      top: 15.625rem
      left: 50%
      margin-left: -1.5rem
    td, th
      width: 33.33%
      padding: 1rem 0rem
    th
      text-align: left
    td
      border-top: 0.0625rem solid $c-primary-lighter
      position: relative
      height: 3.3125rem
      .statistics-loading
        position: absolute
        left: 0
        top: 0.125rem
        margin-top: 0.75rem
  .loading
    opacity: 0.5
    pointer-events: none
</style>