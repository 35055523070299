<template lang="pug">
.available-fields-section.thin-border-primary-lighter.my-6.py-6.px-6
  .d-flex.justify-space-between
    h3.nio-h3.text-primary-darker.mb-4 Schema
    .d-flex.align-center
      .field-count.nio-h4.text-primary-darker.mr-2 {{ fieldsCountString }},
      .nio-h4.text-primary-darker.label.mr-2 Primary field:
      .nio-h4.text-primary-darker(v-if="dataset && dataset.schema.primary") {{ dataset.schema.primary }}
      NioButton(
        normal-tertiary,
        @click.prevent="handleEdit",
        v-if="displayOnly"
      ) Edit
      NioButton(normal-tertiary, @click.prevent="updateFields", v-else) Save
  .datasets
    NioExpansionPanels
      .header-slot(v-for="(item, id) in fields", :key="item.order") 
        TreeMenu(
          :id="id",
          :field="item",
          :draggable="false",
          :display-only="displayOnly",
          :isEdit="true"
          :required-fields="required",
          :unsellable-fields="unsellable",
          :sensitive-fields="sensitive",
          field-type="json",
          ref="treemenu",
          :isNested="false"
          @toggleChanged="toggleChanged"
        ) 
</template>

<script>

import TreeMenu from "@/shared/components/TreeMenu"
import { countFieldsFromArray } from "@/shared/utils/datasetConversions"
import { mapGetters, mapActions } from "vuex"

export default {
  data() {
    return {
      displayOnly: true,
      fieldsCountString: "",
      required: [],
      unsellable: [],
      sensitive: [],
      attributes: [],
      fields: null,
      previousEFieldType: null,
    }
  },
  components: {
    TreeMenu,
  },
  computed: {
    ...mapGetters([
      'getFieldsAsArray',
      'getFieldsAsJson',
      'getPrimaryFieldOptions',
      'getEditField',
      'getEditPath',
      'getFieldsValidation']),
  },
  props: {
    dataset: {
      type: Object,
      required: true,
    },
  },
  watch: {
    getFieldsAsArray: {
      deep: true,
      handler(val) {
        this.fields = val
        const fieldsCount = countFieldsFromArray(val)
        let fieldsCountString = `${fieldsCount.totalFields} field${fieldsCount.totalFields > 1 ? 's' : ''}`
        if (this.fieldType === 'json') {
          if (fieldsCount.nestedFields > 0) {
            fieldsCountString += `, ${fieldsCount.nestedFields} nested`
          }
        }
        this.fieldsCountString = fieldsCountString
      }
    },
    getEditField: {
      deep: true,
      handler(val) {
        if (!val || val.isNew) {
          this.locEditField = {
            approximate_cardinality: '',
            fieldValues: 'any',
            description: '',
            enum: [],
            is_optional: true,
            is_sellable: true,
            is_sensitive: false,
            name: '',
            type: 'string'
          }
        } else {
          this.previousEFieldType = val.type
          this.locEditField = { ...val }
        }
      }
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions(["setFieldsFromJson", "setDatasetType", "setFieldToEdit"]),
    toggleChanged(event) {
      this.setFieldToEdit(event.path).then(() => {
        this.locEditField = this.getEditField
        if (event.type === 'sellable') {
          this.locEditField.is_sellable = event.value
        } else if (event.type === 'required') {
          this.locEditField.is_optional = !event.value
        } else if (event.type === 'sensitive') {
          this.locEditField.is_sensitive = event.value
        }
      })
    },
    updateFields() {
      this.$emit("updateFields", this.getFieldsAsArray)
    },
    handleEdit() {
      this.displayOnly = false
    },
    init() {
      this.required = this.dataset.schema.required || []
      this.unsellable = this.dataset.schema.unsellable || []
      this.sensitive = this.dataset.schema.sensitive || []

      this.setDatasetType(this.dataset.schema.file_config.type)
      this.setFieldsFromJson(this.dataset.schema)
      const fieldsCount = countFieldsFromArray(this.getFieldsAsArray)
      let fieldsCountString = `${fieldsCount.totalFields} field${fieldsCount.totalFields > 1 ? "s" : ""
        }`
      if (this.dataset.schema.file_config.type === "json") {
        if (fieldsCount.nestedFields > 0) {
          fieldsCountString += `, ${fieldsCount.nestedFields} nested`
        }
      }

      this.fieldsCountString = fieldsCountString
    },
  },
}
</script>
<style lang="sass" scoped>
@import "@narrative.io/tackle-box/src/styles/global/_colors"

.available-fields-section
  min-height: 25rem
  .datasets
    .header-slot
      width: 100%
</style>