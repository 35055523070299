
<template lang="pug">
.column-chart.d-flex.mb-4(v-if="options")
  .d-flex
  .chart
    .d-flex
      NioIcon(
        :name="dataTypeIconName(options.column.type)",
        :size="16",
        color="#AEB9E8"
      )
      .ml-2
        h4.nio-h4.text-primary-darker {{ options.column.name }}
        p.nio-p.text-primary-darker N/A
    .chart-wrapper
      //- NioHistogram(:options="options")
  .meta.flex-1.ml-4
    .d-flex.flex-1.mb-2
      .box.dark-blue(:style="{ width: `${nonNullValueCountPercent}%`}")
      .box.light-blue(:style="{ width: `${nullValueCountPercent}%` }")
    .d-flex.align-center.justify-space-between.flex-1
      .d-flex.flex-1.align-center
        .box.dark-blue.mr-2
        p.nio-p.text-primary-darker.mb-0 Non-null value count
      .d-flex.ml-2
        p.nio-p.text-primary-darker.mb-0 {{ format(nonNullValueCount) }}
        p.nio-p.text-primary-light.ml-2.mb-0 {{nonNullValueCountPercent}}%
    .d-flex.flex-1
      .flex-1.d-flex.align-center
        .box.light-blue.mr-2
        p.nio-p.text-primary-darker.mb-0 Null value count
      .d-flex.ml-2
        p.nio-p.text-primary-darker.mb-0 {{ format(nullValueCount) }}
        p.nio-p.text-primary-light.ml-2.mb-0 {{nullValueCountPercent}}%
    .d-flex.flex-1.justify-space-between.mt-4
      p.nio-p.text-primary-darker.mb-0 NaN value count
      p.nio-p.text-primary-darker.mb-0 {{ format(options.column.nan_value_count) }}
    .d-flex.flex-1.justify-space-between
      p.nio-p.text-primary-darker.mb-0 Lower bound
      p.nio-p.text-primary-darker.mb-0 {{ format(options.column.lower_bound) }}
    .d-flex.flex-1.justify-space-between
      p.nio-p.text-primary-darker.mb-0 Upper bound
      p.nio-p.text-primary-darker.mb-0 {{ format(options.column.upper_bound) }}
    .d-flex.flex-1.justify-space-between
      p.nio-p.text-primary-darker.mb-0 Column stored bytes
      p.nio-p.text-primary-darker.mb-0 {{ format(options.column.column_store_bytes) }}
</template>


<script>

import { getDataTypeIconName } from "@narrative.io/tackle-box/src/modules/app/schema/attributeModule"

export default {
  components: {
  },
  props: {
    options: {
      type: Object,
      default: null,
    },
  },
  computed: {
    valueCount() {
      return this.options.column.value_count || 0
    },
    nullValueCount() {
      return this.options.column.null_value_count || 0
    },
    nonNullValueCount() {
      return this.valueCount - this.nullValueCount
    },
    nonNullValueCountPercent() { 
      return this.valueCount === 0 ? 0 : Math.round((this.nonNullValueCount / this.valueCount) * 100)
    },
    nullValueCountPercent() {
      return this.valueCount === 0 ? 0 : Math.round((this.nullValueCount / this.valueCount) * 100)
    }
  },
  methods: {
    format(value) {
      if (typeof value != "number") return value || 'N/A'
      let formatter = Intl.NumberFormat('en', { notation: 'compact' })
      return formatter.format(value)
    },
    dataTypeIconName(type) {
      return getDataTypeIconName({ type: type?.toLocaleLowerCase() })
    },
  }
};
</script>

<style lang="sass" scoped>
@import "@narrative.io/tackle-box/src/styles/global/_colors"
.flex-1
  flex: 1

$dark-blue: #4F64AF
$light-blue: #AEB9E8

.column-chart
  .chart-wrapper
    margin-left: -2.1875rem
  .chart, .meta
    flex: 0.5 
  .box
    width: 0.75rem
    height: 0.75rem
  .box.dark-blue
    background-color: $dark-blue
  .box.light-blue
    background-color: $light-blue
</style>