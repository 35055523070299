<template lang="pug">
.thin-border-primary-lighter.my-6.py-6.px-6.attribute-mappings-section
  .attribute-mappings(v-if="dataset && attributes")
    h3.nio-h3.text-primary-darker.mb-4 Attribute Mappings
    .existing-mappings(v-if="makeExistingAttributeMappings(dataset)") 
      .existing-mapping(
        v-for="mapping in makeExistingAttributeMappings(dataset)",
        :class="{ deleting: deletingMapping(mapping.id) }"
      )
        .mapping-property.status
          .label.nio-p.text-primary-dark Status:
          .nio-p.text-primary-darker.nio-bold(:class="mapping.status") {{ makeMappingStatus(mapping.status) }}
        .mapping-property
          .label.nio-p.text-primary-dark Dataset field:
          .nio-p.text-primary-darker.nio-bold {{ makeFieldName(mapping.expression) }}
        .mapping-property
          .label.nio-p.text-primary-dark Narrative attribute name:
          .nio-p.text-primary-darker.nio-bold {{ mapping.attributeName }}
        .mapping-property(v-if="mapping.path")
          .label.nio-p.text-primary-dark Attribute child property:
          .nio-p.text-primary-darker.nio-bold {{ mapping.path }}
        .mapping-property
          .label.nio-p.text-primary-dark Scope:
          NioPill(
            v-if="mapping.scope === 'global'"
            text="global"
            iconName="utility-lock"
            tag
            append-icon
            :iconColor="iconColor"
          )
          NioPill(
            v-else
            text="private"
            tag
          )
        NioButton.delete-mapping(
          v-if="mapping.scope !== 'global'"
          caution-text,
          @click="showDeleteMappingDialog(mapping, dataset)"
        ) Delete mapping
        v-progress-circular.deleting-attribute-mappings(
          v-if="deletingMapping(mapping.id)",
          size="30",
          color="#1438F5",
          indeterminate
        )
    .no-existing-mappings(v-else)
      p.nio-p.text-primary-dark There are no attribute mappings for this dataset
    .add-attribute-mappings(:class="{ saving: savingAttributeMappings }")
      UserProposedAttributeMappings(
        :properties="getFieldsAsArray",
        :attributes="attributes",
        :for-existing-dataset="true",
        :existing-mappings="privateMappings",
        @attributeMappingsChanged="updateAttributeMappings($event)",
        @saveAttributeMappings="saveAttributeMappings(dataset.id)"
      )
      v-progress-circular.saving-attribute-mappings(
        v-if="savingAttributeMappings",
        size="40",
        color="#1438F5",
        indeterminate
      )
    NioDialog(
      v-model="attributeMappingsErrorDialog" 
    )
      AttributeMappingsErrorDialog(
        :errors="attributeMappingsErrors"
        :forNewDataset="false"
        @close="attributeMappingsErrorDialog = false"
      )    
    NioDialog(
      v-model="confirmDeleteMappingDialog" 
    )
      ConfirmDeleteMappingDialog(
        v-if="mappingToDeleteDataset"
        :mapping="mappingToDelete"
        :existingMappings="makeExistingAttributeMappings(mappingToDeleteDataset)"
        @confirm="deleteAttributeMapping(mappingToDelete.id)"
        @cancel="confirmDeleteMappingDialog = false"
      )
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import { NioOpenApiModule } from '@narrative.io/tackle-box'
import { replacePropertyRefs } from '@narrative.io/tackle-box/src/modules/app/schema/attributeModule'
import { makeAttributeMappings } from '@/modules/mappingsModule'
import { getThemeColor } from '@narrative.io/tackle-box/src/modules/app/theme/theme'
import UserProposedAttributeMappings from '@/shared/components/attribute-mappings/UserProposedAttributeMappings'
import AttributeMappingsErrorDialog from '@/shared/components/attribute-mappings/AttributeMappingsErrorDialog'
import ConfirmDeleteMappingDialog from '@/shared/components/attribute-mappings/ConfirmDeleteMappingDialog.vue'

export default {
  components: {
    UserProposedAttributeMappings,
    AttributeMappingsErrorDialog,
    ConfirmDeleteMappingDialog
  },
  props: {
    dataset: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      datasets: null,
      attributes: null,
      attributeMappings: null,
      showAttributeMappingsControls: false,
      attributeMappingsErrorDialog: false,
      attributeMappingsErrors: null,
      savingAttributeMappings: false,
      confirmDeleteMappingDialog: false,
      mappingToDelete: null,
      mappingToDeleteDataset: null,
      deletingMappingsIds: [],
      deactivating: false,
    }
  },
  computed: {
    ...mapGetters(['getFieldsAsArray']),
    iconColor() {
      return getThemeColor('primaryDark')
    },
    privateMappings() {
      return this.existingMappings?.filter(mapping => mapping.scope === 'private')
    }
  },
  mounted() {
    NioOpenApiModule.initCallback(this.openApiInit)
  },
  methods: {
    ...mapActions(['setFieldsFromJson', 'setDatasetType']),
    openApiInit() {
      this.getAttributes()
    },
    getAttributes() {
      this.$nioOpenApi.get('/attributes').then(res => {
        this.attributes = res.data?.records.map(attribute => {
          return {
            ...replacePropertyRefs(attribute, res.data.records),
            id: attribute.id
          }
        })
      })
    },
    makeExistingAttributeMappings(dataset) {
      if (dataset.mappings && dataset.mappings.length > 0) {
        return dataset.mappings.reduce((acc, mapping) => {
          const mappedAttribute = this.attributes?.find(attribute => attribute.id === mapping.attribute_id)
          if (mappedAttribute && mapping.mapping) {
            if (mapping.mapping.property_mappings && mapping.mapping.property_mappings.length > 0) {
              return [...acc, ...mapping.mapping.property_mappings.map(propertyMapping => {
                return {
                  ...propertyMapping,
                  attributeName: mappedAttribute.display_name,
                  id: mapping.id,
                  status: mapping.status,
                  scope: mapping.scope
                }
              })]
            } else {
              return [...acc, {
                expression: mapping.mapping.expression,
                attributeName: mappedAttribute.display_name,
                id: mapping.id,
                status: mapping.status,
                scope: mapping.scope
              }]
            }
          }
          return acc
        }, [])
      }
      return null
    },
    makeFieldName(name) {
      if (name.indexOf('(') > -1 || name.indexOf(')') > -1) {
        const regex = /\((.*)\)/i
        return name.match(regex)[1]
      }
      return name
    },
    updateAttributeMappings(mappings) {
      this.attributeMappings = mappings
    },
    async saveAttributeMappings(datasetId) {
      this.savingAttributeMappings = true
      const mappings = makeAttributeMappings(datasetId, this.attributeMappings, this.getFieldsAsArray, this.attributes)
      const errors = []
      const promises = mappings.map(mapping => {
        return this.$nioOpenApi.post(`mappings/companies/${this.nioUser.companyId}`, mapping)
      })
      const resp = await Promise.all(promises.map(p => p.catch(err => {
        errors.push(err.response.data.error_description);
      })))
      if (errors.length === 0) {
        this.$emit('getDataset', () => {
          this.savingAttributeMappings = false
        })
        
      } else {
        this.savingAttributeMappings = false
        this.attributeMappingsErrorDialog = true
        this.attributeMappingsErrors = errors
        this.attributeMappings = []
      }
    },
    showDeleteMappingDialog(mapping, dataset) {
      this.mappingToDeleteDataset = dataset
      this.mappingToDelete = mapping
      this.confirmDeleteMappingDialog = true
    },
    async deleteAttributeMapping(mappingId) {
      this.confirmDeleteMappingDialog = false
      this.deletingMappingsIds.push(mappingId)
      const resp = await this.$nioOpenApi.delete(`mappings/${mappingId}`)
      this.$emit('getDataset', () => {
        this.deletingMappingsIds = []
      })
    },
    deletingMapping(mappingId) {
      return this.deletingMappingsIds.includes(mappingId)
    },
    makeMappingStatus(status) {
      return status.charAt(0).toUpperCase() + status.slice(1)
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@narrative.io/tackle-box/src/styles/global/_colors"
.attribute-mappings
  .existing-mappings
    .existing-mapping
      position: relative
      display: flex
      flex-direction: column
      padding: 0.5rem 1rem
      border: 0.0625rem solid $c-primary-lighter
      background-color: $c-white
      border-radius: 0.75rem
      &.deleting
        opacity: 0.5
        pointer-events: none
      & > .mapping-property + .mapping-property
        margin-top: 0.5rem
      .mapping-property
        display: flex
        align-items: center
        .label
          width: 13.75rem
          flex-grow: 0
          flex-shrink: 0
        .active
          color: $c-success
        .archived
          color: $c-error
        .pending
          color: $c-warning
      .delete-mapping
        position: absolute
        bottom: 0rem
        right: 1rem
      .deleting-attribute-mappings
        position: absolute
        right: 50%
        margin-right: -0.9375rem
        top: 50%
        margin-top: -0.9375rem
    & > .existing-mapping + .existing-mapping
      margin-top: 0.75rem
.add-attribute-mappings
  margin-top: 0.75rem
  position: relative
  .saving-attribute-mappings
    position: absolute
    left: 50%
    margin-left: -1.25rem
    top: 50%
    margin-top: -1.25rem
  &.saving .user-proposed-attribute-mappings
    opacity: 0.5
    pointer-events: none
.name-fields
  display: none
.nio-expansion-panels
  overflow: hidden
  &:first-child
    border-radius: 0.75rem
  ::v-deep .v-expansion-panel-header
</style>