<template lang="pug">
.file-ingestion-section.thin-border-primary-lighter.my-6.py-6.px-6
  h3.nio-h3.text-primary-darker.mb-4 Ingestion Log
  DatasetStatistics(:dataset="dataset")
</template>

<script>

import DatasetStatistics from "@/shared/components/DatasetStatistics.vue";

export default {
  components: {
    DatasetStatistics
  },
  props: {
    dataset: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="sass" scoped>
.file-ingestion
  min-height: 25rem
</style>